import axios from 'axios';
import React, { useState } from 'react';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const Touch = () => {
  const showAlert = () => {
    Swal.fire({
      title: 'SUCCESS',
      text: 'Message sent successfully!',
      icon: 'success',
      confirmButtonText: 'OK',
    });
  };

  const [error, setError] = useState({
    firstname: '',
    lastname: '',
    email: '',
    message: '',
  });

  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validate = () => {
    let isValid = true;
    const newError = { firstname: '', lastname: '', email: '', message: '' };

    const nameRegex = /^[a-zA-Z]{3,}$/;
    if (!formData.firstname.trim() || !nameRegex.test(formData.firstname.trim())) {
      newError.firstname = 'Firstname is required';
      isValid = false;
    }

    if (!formData.lastname) {
      newError.lastname = 'Last name is required';
      isValid = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim() || !emailRegex.test(formData.email.trim())) {
      newError.email = 'Email is required';
      isValid = false;
    }

    if (!formData.message) {
      newError.message = 'Message is required';
      isValid = false;
    }

    setError(newError);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) {
      return;
    }

    await axios.post('https://dv-homes-front.vercel.app/api/contact', {
      firstName: formData.firstname,
      lastName: formData.lastname,
      email: formData.email,
      message: formData.message,
    });
    showAlert();

    setFormData({
      firstname: '',
      lastname: '',
      email: '',
      message: '',
    });
  };

  return (
    <div className="flex justify-center items-center">
      <div className="gap-5 flex max-md:flex-col mt-32 max-md:items-stretch max-md:gap-0">
        <div className="flex flex-col items-stretch w-[48%] max-md:w-full max-md:ml-0">
          <div className="flex flex-col px-5 items-start max-md:max-w-full max-md:mt-10">
            <div className="text-primary1 text-5xl font-bold leading-[56.16px] self-stretch max-md:max-w-full max-md:text-4xl">Want to get in touch?</div>
            <div className="text-slate-900 text-lg leading-6 self-stretch mt-4 max-md:max-w-full">We’d love to hear from you.</div>
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/98ce67d0e40afd44d8481f9d260b5f009e44ccb344a35839b5b6861f4ec2db89?apiKey=60bd44a890ac4356aa91264734464f16&" className="aspect-[1.95] object-contain object-center w-[467px] overflow-hidden max-w-full mt-16 max-md:mt-10" alt="dv" />
          </div>
        </div>
        <div className="flex flex-col items-stretch w-[52%] ml-5 max-md:w-full max-md:ml-0">
          <div className="items-start bg-stone-50 flex grow flex-col w-full p-6 rounded-lg max-md:max-w-full max-md:mt-10 max-md:px-5">
            <div className="text-slate-900 text-2xl font-bold leading-8 self-stretch max-md:max-w-full">Have Questions?</div>
            <div className="text-slate-500 text-lg leading-6 self-stretch mt-2.5 max-md:max-w-full">Fill out the form and we’ll be in touch soon!</div>
            <form onSubmit={handleSubmit}>
              <div className="items-stretch self-stretch flex justify-between gap-1 mt-6 max-md:max-w-full max-md:flex-wrap">
                <div className="items-stretch flex grow basis-[0%] flex-col">
                  <label for="firstname" className="text-slate-900 text-base leading-6">
                    First name
                  </label>
                  <input type="text" id="firstname" name="firstname" value={formData.firstname} onChange={handleChange} placeholder="Enter your first name" class=" text-base border  border-gray-300 p-2 leading-6 whitespace-nowrap justify-center  mt-2.5 w-full md:max-w-72 pr-16 py-3 rounded-lg items-start max-md:pr-5"></input>
                  <div className="text-rose-800">{error.firstname}</div>
                </div>

                <div className="items-stretch flex grow basis-[0%] flex-col">
                  <label for="lastname" className="text-slate-900 text-base leading-6">
                    Last name
                  </label>
                  <input type="text" id="lastname" name="lastname" value={formData.lastname} onChange={handleChange} placeholder="Enter your last name" className=" text-base border   border-gray-300  p-2 leading-6 whitespace-nowrap justify-center  mt-2.5  w-full md:max-w-72 pr-16 py-3 rounded-lg items-start max-md:pr-5"></input>
                  <div className="text-rose-800">{error.lastname}</div>
                </div>
              </div>
              <div class="mb-6">
                <label for="email" class="block text-slate-900 text-base p-2 leading-6">
                  Email
                </label>
                <input type="mail" id="email" name="email" value={formData.email} onChange={handleChange} placeholder="Enter your email address" class="mt-1 p-3 border w-full  border-gray-300 rounded-md"></input>
                <div className="text-rose-800">{error.email}</div>
              </div>
              <div class="mb-6">
                <label for="message" class="block text-slate-900 text-base leading-6">
                  Message
                </label>
                <textarea id="message" name="message" value={formData.message} onChange={handleChange} placeholder="Enter your message" class="mt-1 p-3 w-full md:max-w-full border border-gray-300 rounded-md"></textarea>
                <div className="text-rose-800">{error.message}</div>
              </div>

              <button className="text-zinc-50 text-base leading-6 whitespace-nowrap transition duration-500 justify-center items-stretch bg-primary1 hover:bg-secondary1 mt-6 px-6 py-4 rounded-[30px] self-start max-md:px-5">Send Message</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Touch;
