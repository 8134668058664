import React, { useState, useEffect } from 'react';
import { Bars3BottomRightIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
  let [isOpen, setisopen] = useState(false);

  const location = useLocation();
  const [color, setColor] = useState(false);
  const isHomepage = location.pathname === '/';

  const handleScroll = () => {
    if (window.scrollY <= 50) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  const closeMenu = () => {
    setisopen(false);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Add smooth scrolling effect
    });
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div>
      <div className="w-full fixed top-0 left-0 z-50 transition-all duration-100 ">
        <div className={`md:flex items-center justify-between py-4 md:pl-40 md:pr-40 ${isHomepage && color ? '' : 'bg-neutral-50'}`}>
          {/* logo section */}
          <div className="font-bold text-2xl cursor-pointer pl-5 flex items-center gap-1">
            <Link
              className="w-[63.9px]"
              to="/"
              onClick={() => {
                closeMenu();
                scrollToTop();
              }}
            >
              {' '}
              <img src="../img/dv-homes.png" alt="logo" />{' '}
            </Link>
          </div>
          {/* Menu icon */}
          <div onClick={() => setisopen(!isOpen)} className="absolute right-8 top-6  cursor-pointer md:hidden w-9 h-9">
            {isOpen ? <XMarkIcon /> : <Bars3BottomRightIcon />}
          </div>
          {/* link items */}
          <ul className={` md:flex md:items-center md:pb-0 pb-12 absolute md:static md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ${isOpen ? 'top-20 ' : 'top-[-490px]'} ${isOpen ? 'bg-neutral-50' : ''}`}>
            <li className={`md:ml-8 md:my-0 hover:text-primary1 my-7 ${location.pathname === '/' ? 'text-secondary1' : 'text-black'}`}>
              <Link
                to="/"
                className="font-custom w-fit tracking-[0] text-[18px] font-medium leading-[22px] whitespace-nowrap relative text-black"
                c
                onClick={() => {
                  closeMenu();
                  scrollToTop();
                }}
              >
                Home
              </Link>
            </li>
            <li className={`md:ml-8 md:my-0 hover:text-primary1 my-7 ${location.pathname === '/buy' ? 'text-secondary1' : 'text-black'}`}>
              <a href="https://dvhomes.idxbroker.com/idx/map/mapsearch" className="font-custom w-fit tracking-[0] text-[18px] font-medium leading-[22px] whitespace-nowrap relative text-black" onClick={closeMenu}>
                Buy
              </a>
            </li>
            <li className={`md:ml-8 md:my-0 hover:text-primary1 my-7 ${location.pathname === '/sell' ? 'text-secondary1' : 'text-black'}`}>
              <Link to="/sell" className="font-custom w-fit tracking-[0] text-[18px] font-medium leading-[22px] whitespace-nowrap relative text-black" onClick={closeMenu}>
                Sell
              </Link>
            </li>
            <li className={`md:ml-8 md:my-0 hover:text-primary1 my-7 ${location.pathname === '/worth' ? 'text-secondary1' : 'text-black'}`}>
              <a href="https://dvhomes.idxbroker.com/idx/homevaluation" className="font-custom w-fit tracking-[0] text-[18px] font-medium leading-[22px] whitespace-nowrap relative text-black" onClick={closeMenu}>
                Home Valuation
              </a>
            </li>
            <li className={`md:ml-8 md:my-0 hover:text-primary1 my-7 ${location.pathname === '/market' ? 'text-secondary1' : 'text-black'}`}>
              <a href="https://dvhomes.idxbroker.com/idx/market-reports" className="font-custom w-fit tracking-[0] text-[18px] font-medium leading-[22px] whitespace-nowrap relative text-black" onClick={closeMenu}>
                Market Report
              </a>
            </li>
            <li className={`md:ml-8 md:my-0 hover:text-primary1 my-7 ${location.pathname === '/market' ? 'text-secondary1' : 'text-black'}`}>
              <a href="https://sports.dvhomes.net" className="font-custom w-fit tracking-[0] text-[18px] font-medium leading-[22px] whitespace-nowrap relative text-black" onClick={closeMenu}>
                Sports
              </a>
            </li>
            <li className={`md:ml-8 md:my-0 hover:text-primary1 my-7 ${location.pathname === '/about' ? 'text-secondary1' : 'text-black'}`}>
              <Link to="/about" className="font-custom w-fit tracking-[0] text-[18px] font-medium leading-[22px] whitespace-nowrap relative text-black" onClick={closeMenu}>
                {' '}
                About Us
              </Link>
            </li>
            {/*  */}
            <li className="md:ml-8 md:my-0 my-7  hover:text-primary1 font-semibold">
              <Link to="/contact" className="font-custom w-fit tracking-[0] text-[18px] font-medium leading-[22px] whitespace-nowrap relative text-black" onClick={closeMenu}>
                <div className="inline-flex items-start gap-[10px] flex-[0_0_auto] px-[16px] py-[10px] rounded-[22px] transition duration-500 bg-primary1 hover:bg-secondary1 relative">
                  <div className="inline-flex items-start gap-[10px] flex-[0_0_auto] pt-0 pb-[2px] px-0 relative">
                    <div className="font-custom w-fit mt-[-1.00px] tracking-[0] text-[18px] font-medium leading-[22px] whitespace-nowrap relative text-slate-50">Get in Touch</div>
                  </div>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
